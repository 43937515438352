import Tile from '@atoms/Tile/Tile';
import Link from '@atoms/Link/Link';

import useCustomRouter from '@hooks/useCustomRouter';
import paths from '@constants/paths';
import webToAppApi from '@api/web-to-app';
import { StyledActiveFilters, StyledTileContainer } from './SelectedFilters.styles';
import type { BreadcrumbDataOfSearchStateData } from '@occ/api-client';

interface Props {
  breadcrumbs: Array<BreadcrumbDataOfSearchStateData>;
}

export interface BreadcrumbDataWithFaceValueName extends BreadcrumbDataOfSearchStateData {
  facetValueName: string;
}

const SelectedFilters = ({ breadcrumbs }: Props) => {
  const router = useCustomRouter();

  const notifyApp = (filterValue: string) => {
    if (router.pathname === paths.OFFERS) {
      webToAppApi.eventOffersFiltered(filterValue);
    }
  };

  return (
    <StyledActiveFilters>
      <StyledTileContainer>
        {breadcrumbs
          .filter((breadcrumb): breadcrumb is BreadcrumbDataWithFaceValueName => breadcrumb.facetValueName !== undefined)
          .map((breadcrumb) => {
            const filterQuery = breadcrumb?.removeQuery?.query?.value;
            return (
              <Link
                internal
                scroll={false}
                href={{ pathname: router.pathname, query: { ...router.query, q: filterQuery } }}
                onClick={() => notifyApp(breadcrumb.facetValueName)}
                key={breadcrumb.facetValueCode}
              >
                <Tile selected={false} name={breadcrumb.facetValueName} />
              </Link>
            );
          })}
      </StyledTileContainer>
    </StyledActiveFilters>
  );
};

export default SelectedFilters;
