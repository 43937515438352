import {
  AxfoodProductDetailsViewModel,
  ComponentWsDTO,
  ArticleNavigationApi,
  AxfoodApi,
  BuyAllProductsApi,
  ProductBannerApi,
  LeftMenuApi,
} from '@occ/api-client';

const articleNavigationApi = new ArticleNavigationApi({
  baseUrl: '',
});

const axfoodApi = new AxfoodApi({
  baseUrl: '',
});

const buyAllProductsApi = new BuyAllProductsApi({
  baseUrl: '',
});

const productBannerApi = new ProductBannerApi({
  baseUrl: '',
});

const leftMenuApi = new LeftMenuApi({
  baseUrl: '',
});

export const getNavigationData = (componentId: string, pageLabel: string) =>
  articleNavigationApi.getNavigationDataUsingGet(componentId, { pageLabel });

export const getItemsForYear = (yearUid: string) => axfoodApi.getItemsForYearUsingGet(yearUid);

export interface ProductAndQuantity extends AxfoodProductDetailsViewModel {
  quantity: number;
}

export interface BuyAllProductsWsDTO {
  title: string;
  products: ProductAndQuantity[];
  gridPackages?: {
    component?: ComponentWsDTO;
  };
}

export const getProductsForBuyAllBanner = async (componentId: string) => {
  const response = await buyAllProductsApi.getProductsForBuyAllBannerUsingGet(componentId);
  return response.data as BuyAllProductsWsDTO;
};

export const getProductBannerPackages = async (componentId: string) => {
  const response = await productBannerApi.getPackagesForProductBannerUsingGet(componentId);
  return response.data;
};

export const getProductBannerComponent = async (
  componentId: string,
  page?: string,
  size?: string,
  promotionStoreUid?: string,
  q?: string,
  sort?: string
) => {
  const response = await productBannerApi.getProductsForProductBannerUsingGet(componentId, {
    page,
    size,
    promotionStoreUid,
    q,
    sort,
  });
  return response.data;
};

export type CategoryNavigationTreeDeviceType = 'ANDROID' | 'IOS' | 'OTHER';
export const getCategoryNavigationTree = async ({
  storeId,
  deviceType,
}: {
  storeId: string;
  deviceType: CategoryNavigationTreeDeviceType;
}) => {
  const { data } = await leftMenuApi.getCategoryNavigationTreeUsingGet({
    storeId,
    deviceType,
  });
  return data as unknown as AccordionNavNodeType;
};
