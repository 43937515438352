import { FormEventHandler, forwardRef, useImperativeHandle, useRef } from 'react';
import { setInputFieldIsDirtyEvent } from '@helpers/form/events';
import { trackFormError } from '@helpers/analyticsHelpers/trackForm';
import { StyledForm } from './Form.styles';

export interface Props
  extends Pick<
    React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
    'onSubmit' | 'children' | 'noValidate' | 'className'
  > {
  trackErrors?: { source: string; prefix?: string };
  beforeSubmit?: FormEventHandler<HTMLFormElement> | undefined;
}

const Form = forwardRef<HTMLFormElement, Props>(
  ({ beforeSubmit, onSubmit, children, noValidate = true, className, trackErrors }: Props, ref) => {
    const formRef = useRef<HTMLFormElement>(null);
    useImperativeHandle(ref, () => formRef.current!, [formRef]);

    const onSubmitHandler: FormEventHandler<HTMLFormElement> = async (e) => {
      e.preventDefault();

      if (beforeSubmit) {
        try {
          await beforeSubmit(e);
        } catch (err) {
          return;
        }
      }

      // Set all inputs to dirty
      formRef?.current?.querySelectorAll<HTMLInputElement>('input, textarea').forEach((element) => {
        element.dispatchEvent(setInputFieldIsDirtyEvent(true));
      });

      if (trackErrors) {
        const source = typeof trackErrors === 'object' && trackErrors.source ? trackErrors.source : '';
        const prefix = typeof trackErrors === 'object' && trackErrors.prefix ? trackErrors.prefix : '';
        const invalid = formRef?.current?.querySelectorAll<HTMLInputElement>('input:invalid, textarea:invalid');

        [...(invalid || [])].forEach((input) => {
          trackFormError(source, input.name, input.validationMessage, prefix);
        });
      }

      const firstInvalid = formRef?.current?.querySelector<HTMLInputElement>(
        'input:invalid:not([role="combobox"]), textarea:invalid'
      );
      if (firstInvalid) {
        return firstInvalid.focus();
      }

      if (onSubmit) onSubmit(e);
    };

    return (
      <StyledForm ref={formRef} className={className} onSubmit={onSubmitHandler} noValidate={noValidate}>
        {children}
      </StyledForm>
    );
  }
);
Form.displayName = 'Form';

export default Form;
