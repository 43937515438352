import { useEffect, useRef } from 'react';
import useTranslation from 'next-translate/useTranslation';
import useCustomRouter from '@hooks/useCustomRouter';
import Link from '@atoms/Link/Link';
import Icon from '@atoms/Icon/Icon';
import paths from '@constants/paths';
import webToAppApi from '@api/web-to-app';
import { StyledCloseIcon } from '@atoms/Tile/Tile.styles';
import Text from '@atoms/Text/Text';
import { BreadcrumbDataOfSearchStateData, FacetDataOfobject } from '@occ/api-client';
import { BreadcrumbDataWithFaceValueName } from '@molecules/SelectedFilters/SelectedFilters';
import PlusSmall from '@icons/plus_small.svg';
import MinusSmall from '@icons/minus_small.svg';
import CloseSmall from '@icons/close_small.svg';
import {
  StyledFilterCheckBoxAndCount,
  StyledFilterList,
  StyledFilterListCheckbox,
  StyledFilterListLabel,
  StyledFilterListOptions,
  StyledFilterListSelectedText,
  StyledFilterListSpan,
} from './FilterList.styles';

type Props = {
  code: string;
  name: string;
  option: FacetDataOfobject;
  expandedListName: string;
  toggleList: () => void;
  breadcrumbs: Array<BreadcrumbDataOfSearchStateData>;
};

const FilterList = ({
  option = {} as FacetDataOfobject,
  name,
  expandedListName,
  toggleList,
  breadcrumbs,
  code,
}: Props) => {
  const filterListOptions = useRef<HTMLDivElement>(null);
  const expandedFilterCategoryRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('productFilter');
  const router = useCustomRouter();
  const { pathname } = router;

  const notifyApp = (filterValue: string) => {
    if (router.pathname === paths.OFFERS) {
      webToAppApi.eventOffersFiltered(filterValue);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (expandedFilterCategoryRef?.current) expandedFilterCategoryRef.current.focus();
    }, 0);
  }, []);

  return (
    <>
      {option && (
        <>
          <StyledFilterList
            ref={expandedListName === option.name ? expandedFilterCategoryRef : null}
            theme="transparent"
            expanded={expandedListName === option.name}
            onClick={toggleList}
            key={option.name}
          >
            <div>
              <StyledFilterListSpan>{name}</StyledFilterListSpan>
              {expandedListName !== option.name && breadcrumbs.length > 0 && (
                <div>
                  {breadcrumbs
                    .filter((breadcrumb) => breadcrumb.facetCode === code)
                    .filter(
                      (breadcrumb): breadcrumb is BreadcrumbDataWithFaceValueName =>
                        breadcrumb.facetValueName !== undefined
                    )
                    .map((breadcrumb, index) => (
                      <StyledFilterListSelectedText
                        type="body"
                        size="small"
                        key={`${breadcrumb.facetValueName}-${index}`}
                      >
                        {`${index > 0 ? ', ' : ''}${breadcrumb.facetValueName}`}
                      </StyledFilterListSelectedText>
                    ))}
                </div>
              )}
            </div>
            {expandedListName === option.name ? (
              <Icon svg={MinusSmall} size={12} color="primary" />
            ) : (
              <Icon svg={PlusSmall} size={12} />
            )}
          </StyledFilterList>
          <StyledFilterListOptions expanded={expandedListName === option.name} ref={filterListOptions}>
            <div>
              {option?.values?.map((value: any) => {
                const filterQuery = value?.query?.query?.value;
                if (value.name && value.code && (filterQuery || (!filterQuery && value?.selected))) {
                  return (
                    <Link
                      internal
                      scroll={false}
                      href={{ pathname, query: { ...router.query, q: filterQuery } }}
                      onClick={() => notifyApp(value.name)}
                      key={value.code}
                      shallow
                      aria-label={t('accessibility->filterOption', { name: value.name, count: value.count })}
                    >
                      <StyledFilterListLabel>
                        <Text type="body" size="medium">
                          {value.name}
                        </Text>

                        <StyledFilterCheckBoxAndCount>
                          {value.count && (
                            <Text type="body" size="medium">
                              {value.count}
                            </Text>
                          )}
                          {!value.count && <StyledCloseIcon svg={CloseSmall} size={9} />}
                          <StyledFilterListCheckbox
                            checked={value.selected}
                            onChange={() => !value.selected}
                            id={value.name}
                          />
                        </StyledFilterCheckBoxAndCount>
                      </StyledFilterListLabel>
                    </Link>
                  );
                }
                return false;
              })}
            </div>
          </StyledFilterListOptions>
        </>
      )}
    </>
  );
};

export default FilterList;
