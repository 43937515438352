import { ChangeEvent, FocusEvent, KeyboardEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import KEYS from '@helpers/keys';
import getQuantity from '@helpers/productHelper';
import AddToCart, { SizeTypes, ThemeTypes } from '@molecules/AddToCart/AddToCart';
import useAddProductQueue from '@hooks/useAddProductQueue';
import useTranslation from 'next-translate/useTranslation';
import Config from '@config';
import useResponsive from '@hooks/useResponsive';
import useCart from '@hooks/useCart';
import useProductQuantityHelper from '@helpers/useProductQuantityHelper';

interface Props {
  product: any; // TODO: What kind of products is this?
  variant: QuantityInputFieldType;
  category?: string;
  quantityCallback?: (value: number) => void;
  mixmatch?: boolean;
  themeOverride?: ThemeTypes;
  sizeOverride?: SizeTypes;
  listName?: string;
  quantity?: number;
  overrideHidingElements?: boolean;
  showBuyButtonForZeroQuantity?: boolean;
}

const ProductQuantityInputField = ({
  product,
  variant,
  category = '',
  quantityCallback,
  mixmatch,
  themeOverride,
  sizeOverride,
  listName,
  quantity,
  overrideHidingElements = false,
  showBuyButtonForZeroQuantity = false,
}: Props) => {
  const { cart, hasFetchedCart } = useCart();
  const [currentQuantity, setCurrentQuantity] = useState<number>(0);
  const [previousQuantity, setPreviousQuantity] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { addToCart } = useAddProductQueue();
  const { t } = useTranslation('cart');
  const { isClient } = useResponsive();
  const unit = product?.productBasketType?.code === 'ST' ? 'pieces' : 'kilogram';

  const { preventIncrementDueToLowStock, shouldEnableWhenOpenOrder, hasReachedMaxQuantity } = useProductQuantityHelper({
    cart,
    quantityInRefOrder: product.quantityInRefOrder,
    currentQuantity,
    inCartUnit: product.unit?.code,
    unit,
    averageWeight: product.averageWeight,
  });

  const inputValue = (e: SyntheticEvent<HTMLInputElement>) => {
    if (unit === 'kilogram') {
      const forceDot = e.currentTarget.value.replace(',', '.');
      return parseFloat(forceDot.replace(/[^0-9.]/g, ''));
    }
    return e.currentTarget.value.replace(/[^0-9]/g, '');
  };

  const setCaretPos = (e: KeyboardEvent<HTMLInputElement>) => {
    const lastDigitPosition = inputValue(e).toString().length;
    inputRef.current?.setSelectionRange(lastDigitPosition, lastDigitPosition);
  };

  const markQuantity = (e: FocusEvent<HTMLInputElement>) => {
    const lastDigitPosition = inputValue(e).toString().length;
    inputRef.current?.setSelectionRange(0, lastDigitPosition);
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = +inputValue(e);

    if (value > Config.PRODUCT.MAX_AMOUNT || value < Config.PRODUCT.MIN_AMOUNT) {
      // Reset the input value to the previous value if the new value is out of bounds
      return;
    }

    if (hasReachedMaxQuantity(value)) {
      return;
    }

    setCurrentQuantity(value);
    // This will set quantity button from + to ok only if the previousQuantity is not equal to current
    setIsActive(previousQuantity !== value);
  };

  const onFocusHandler = (e: FocusEvent<HTMLInputElement>) => {
    setIsActive(true);
    setPreviousQuantity(currentQuantity);
    markQuantity(e);
  };

  const getListNameForTracking = (productCategory: string) => {
    if (listName) return listName;
    if (variant === 'cart') return 'cart';
    if (variant === 'minicart') return 'minicart';
    if (mixmatch) return `multi_product_offer | ${product.code}`;
    return productCategory;
  };

  const onClickHandler = (newQuantity: number) => {
    const cartProduct = cart?.products.find((x) => x.code === product.code);
    const prevQuantity = cartProduct?.pickQuantity || 0;
    const isDecreasing = newQuantity < prevQuantity;
    // We should not look at max quantity when decreasing quantity
    if (newQuantity < Config.PRODUCT.MIN_AMOUNT || (!isDecreasing && newQuantity > Config.PRODUCT.MAX_AMOUNT)) return;
    setCurrentQuantity(newQuantity);

    const listName = getListNameForTracking(category);
    if (typeof quantity === 'undefined') addToCart({ product, newQuantity, prevQuantity, listName, variant });

    if (quantityCallback) quantityCallback(newQuantity);
  };

  const onBlurHandler = (e: FocusEvent<HTMLInputElement>) => {
    const newQuantity = +parseFloat(e.currentTarget.value).toFixed(1);
    if (previousQuantity !== newQuantity) {
      const prevQuantity = previousQuantity;
      const listName = getListNameForTracking(category);
      if (typeof quantity === 'undefined') addToCart({ product, newQuantity, prevQuantity, listName, variant });
      if (quantityCallback) quantityCallback(newQuantity);
    }
    setIsActive(false);
  };

  const onKeyUpHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case KEYS.ENTER:
      case KEYS.ESCAPE:
        return inputRef.current?.blur();
      case KEYS.UP:
        if (isActive) onClickHandler(+inputValue(e) + 1);
        break;
      case KEYS.DOWN:
        if (isActive) onClickHandler(+inputValue(e) - 1);
        break;
      default:
        setCaretPos(e);
    }
    return false;
  };

  useEffect(() => {
    if (quantity) {
      setCurrentQuantity(quantity);
    } else if (hasFetchedCart && cart?.products?.length) {
      const cartProduct = cart.products.find((item: any) => item.code === product.code);
      if (cartProduct) {
        const quantity = getQuantity(cartProduct);
        setCurrentQuantity(quantity);
      } else {
        setCurrentQuantity(0);
      }
    } else {
      setCurrentQuantity(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchedCart, cart?.products]);

  return (
    <AddToCart
      inputRef={inputRef}
      onBlurHandler={onBlurHandler}
      onChangeHandler={onChangeHandler}
      onFocusHandler={onFocusHandler}
      onKeyUpHandler={onKeyUpHandler}
      onClickHandler={onClickHandler}
      isActive={isClient ? document?.activeElement === inputRef?.current : false}
      currentQuantity={currentQuantity}
      unit={unit}
      incrementValue={product.incrementValue}
      outOfStock={product.outOfStock}
      outOfStockLabel={product.bargainProduct ? t('productCard:quantityInput->bargainOutOfStock') : undefined}
      theme={themeOverride || (variant === 'cart' || variant === 'minicart' ? 'secondary' : 'primary')}
      size={sizeOverride || (variant === 'card' ? 'large' : variant === 'minicart' ? 'small' : 'medium')}
      overrideHidingElements={overrideHidingElements}
      showBuyButtonForZeroQuantity={showBuyButtonForZeroQuantity}
      shouldEnableWhenOpenOrder={shouldEnableWhenOpenOrder}
      preventIncrementDueToLowStock={preventIncrementDueToLowStock}
    />
  );
};

export default ProductQuantityInputField;
