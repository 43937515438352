import ProductListFilter from '@organisms/ProductListFilter/ProductListFilter';
import SelectOption from '@molecules/Select/Option';
import SelectBox from '@molecules/Select/SelectBox';
import { useEffect, useMemo, useState } from 'react';
import IconTooltip from '@molecules/IconTooltip/IconTooltip';
import Text from '@atoms/Text/Text';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import Link from '@atoms/Link/Link';
import paths from '@constants/paths';
import {
  FilterAndSortWrapper,
  SelectWrapper,
  StyledAboutThisSearch,
  StyledNumberOfResults,
} from './FilterAndSort.styles';
import type { BreadcrumbDataOfSearchStateData, FacetDataOfobject } from '@occ/api-client';
import SelectedFilters from '@molecules/SelectedFilters/SelectedFilters';
import useResponsive from '@hooks/useResponsive';
import useCustomRouter from '@hooks/useCustomRouter';
import Questionmark from '@icons/non-standard/QUESTIONMARK-16px.svg';

interface Props {
  totalResults?: number;
  filterOptions?: FacetDataOfobject[];
  selectedFilters?: Array<BreadcrumbDataOfSearchStateData>;
  sortOptions?: OptionType[] | null;
  onSort?: (option: OptionType) => void;
  gtmTrackOnFilterOpened?: boolean;
  forceHideRelevanceTooltip?: boolean;
  isLoading?: boolean;
}

const FilterAndSort = ({
  totalResults = 0,
  filterOptions,
  selectedFilters = [],
  sortOptions,
  onSort,
  gtmTrackOnFilterOpened = true,
  forceHideRelevanceTooltip = false,
  isLoading,
}: Props) => {
  const [selectedOption, setSelectedOption] = useState({} as OptionType);
  const { t } = useTranslation('tooltip');
  const showRelevanceTooltip =
    !forceHideRelevanceTooltip && selectedOption?.value === 'relevance' && selectedOption?.selected && totalResults > 0;
  const { isMobile } = useResponsive();
  const router = useCustomRouter();

  const isSearchResultPage = useMemo(() => {
    return router.asPath.startsWith(paths.SEARCH);
  }, [router.asPath]);

  const isPromotionPage = useMemo(() => {
    return router.asPath.startsWith(paths.OFFERS);
  }, [router.asPath]);

  useEffect(() => {
    if (sortOptions?.length && Object.keys(selectedOption).length === 0) setSelectedOption(sortOptions[0]);
  }, [sortOptions]);

  return (
    <>
      <FilterAndSortWrapper noMargin={!!showRelevanceTooltip}>
        <ProductListFilter
          totalNumberOfResults={totalResults}
          breadcrumbs={selectedFilters}
          facets={filterOptions}
          gtmTrackOnFilterOpened={gtmTrackOnFilterOpened}
          isLoading={isLoading}
        />

        {isPromotionPage && (
          <Text type="body" size="small">
            {t('searchresultpage:totalResults', { totalResult: totalResults || 0 })}
          </Text>
        )}

        {totalResults > 0 && sortOptions && sortOptions.length > 0 && (
          <SelectWrapper
            avoidViewport
            options={sortOptions}
            selectBoxComponent={() => <SelectBox type="subtitle" size="small" currentSelection={selectedOption} />}
            optionComponent={({ option, isSelected, onItemClickHandler }) => (
              <SelectOption
                key={option.value}
                option={option}
                isSelected={isSelected}
                onItemClick={() => {
                  if (onSort) {
                    setSelectedOption(option);
                    onSort(option);
                  }
                  onItemClickHandler(option);
                }}
              />
            )}
            selectedOptionIndex={sortOptions.findIndex((so) => so.selected)}
            testId="filterandsort-select"
          />
        )}
      </FilterAndSortWrapper>
      {selectedFilters?.length > 0 && !isMobile && <SelectedFilters breadcrumbs={selectedFilters} />}

      {(isSearchResultPage || showRelevanceTooltip) && (
        <StyledAboutThisSearch>
          {isSearchResultPage && isMobile && (
            <StyledNumberOfResults type="body" size="small" color="black" data-testid="search-result-total-amount">
              {t('searchresultpage:totalResults', { totalResult: totalResults })}
            </StyledNumberOfResults>
          )}

          {showRelevanceTooltip && (
            <>
              <Text type="body" color="black" size="small">
                {t('tooltip->aboutThisSearchResult')}
              </Text>

              <IconTooltip
                openOnClick
                align="center"
                position="bottom"
                content={
                  <Trans
                    i18nKey="tooltip:tooltip->aboutThisHits"
                    components={[
                      <Link key={1} href={paths.ARTICLE.PERSONALIZATION}>
                        {}
                      </Link>,
                    ]}
                  />
                }
                iconProps={{ svg: Questionmark, size: 16 }}
                variant="medium"
              />
            </>
          )}
        </StyledAboutThisSearch>
      )}
    </>
  );
};

export default FilterAndSort;
